import React from "react";
import AppButton from "../forms/AppButton";
import { FaArrowRight } from "react-icons/fa";
import SubHeader from "../common/SubHeader";
import AppHeader from "../common/AppHeader";

// import headerImage from "/assets/images/slider/slide_2.jpeg"

const HeaderPage = () => {
  return (
    <div className="relative overflow-hidden">
      <img
        src={"/assets/images/slider/slide_2.jpeg"}
        height={500}
        width={900}
        className="w-full h-[75vh]"
        alt="main image"
      />
      {/* header description */}
      <div className="flex flex-col absolute top-[20%] px-5 md:px-0 md:left-[10%] gap-7 w-full md:w-[500px] z-10 ">
        <SubHeader color="text-white">Solutions For Online Travel Agency</SubHeader>
        <AppHeader color="text-white">B2B Solution is Integrated with GDS and LLC Airlines</AppHeader>
        <p className="text-xl md:text-2xl text-white">
          {` Agent's`} Management with Debit and Credit Limites and authorities
          double entry finanial system.
        </p>
        <AppButton name="Get Details" icon={<FaArrowRight/>}/>
      </div>
      {/* dark color on surface */}
      <div className="absolute inset-0 bg-[#0000003d] z-0"></div>
    </div>
  );
};

export default HeaderPage;
